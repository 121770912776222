<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <!--<el-breadcrumb separator="/">
				<el-breadcrumb-item>系统管理</el-breadcrumb-item>
				<el-breadcrumb-item>系统用户管理</el-breadcrumb-item>
      </el-breadcrumb>-->
      <el-button
        class="right"
        type="primary"
        size="medium"
        @click="$router.go(-1)"
        >返回</el-button
      >
    </div>
    <!--主体内容-->
    <div class="content">
      <!-- <TitleModule :title="'预约订单详情'"></TitleModule> -->
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <el-form
          label-position="left"
          label-width="200px"
          :model="formInline"
          style="width: 100%; float: left; margin-left: 0"
        >
          <span class="messTitle">订单信息</span>
          <el-form-item
            label="错峰卡名称:"
            prop="staggeredName"
            style="padding-left: 100px; margin-bottom: 0"
          >
            <span>{{ formInline.staggeredName }}</span>
          </el-form-item>
          <el-form-item
            label="停车场名称:"
            prop="parkName"
            style="padding-left: 100px; margin-bottom: 0"
          >
            <span>{{ formInline.parkName }}</span>
          </el-form-item>
          <el-form-item
            label="手机号:"
            prop="mobile"
            style="padding-left: 100px; margin-bottom: 0"
          >
            <span>{{ formInline.mobile }}</span>
          </el-form-item>
          <el-form-item
            label="车牌号:"
            prop="plateNumber"
            style="padding-left: 100px; margin-bottom: 0"
          >
            <span>{{
              formInline.plateNumber &&
              formInline.plateNumber.indexOf("无") != -1
                ? "无牌车"
                : formInline.plateNumber
            }}</span>
          </el-form-item>
          <el-form-item
            label="同步MS状态:"
            prop="syncStatusName"
            style="padding-left: 100px; margin-bottom: 0"
          >
            <span :class="formInline.syncStatus | colorFilter">{{
              formInline.syncStatusName
            }}</span>
            <!-- <span>{{formInline.syncStatusName}}</span> -->
          </el-form-item>
          <el-form-item
            label="订购时间:"
            prop="payTime"
            style="padding-left: 100px; margin-bottom: 0"
          >
            <span>{{ formInline.payTime }}</span>
          </el-form-item>
          <el-form-item
            label="支付方式:"
            prop="payTypeName"
            style="padding-left: 100px; margin-bottom: 0"
          >
            <span>{{ formInline.payTypeName }}</span>
          </el-form-item>
          <el-form-item
            label="订单金额:"
            prop="actualMoney"
            style="padding-left: 100px; margin-bottom: 0"
          >
            <span v-if="formInline.actualMoney"
              >{{ formInline.actualMoney / 100 }}元</span
            >
          </el-form-item>
          <span class="messTitle">商品信息</span>
          <el-form-item
            label="销售金额:"
            prop="mobile"
            style="padding-left: 100px; margin-bottom: 0"
          >
            <span v-if="formInline.staggeredPrice"
              >{{ formInline.staggeredPrice / 100 }}元/天</span
            >
          </el-form-item>
          <el-form-item
            label="有效时间:"
            prop="mobile"
            style="padding-left: 100px; margin-bottom: 0"
          >
            <!-- <div v-for="(item, index) in formInline.staggeredDates.split(',')" :key="index">{{item}}</div> -->
            <div>
              {{
                formInline.orderDetailList[0].startTime.split(" ")[0] +
                " - " +
                formInline.orderDetailList[
                  formInline.orderDetailList.length - 1
                ].endTime.split(" ")[0]
              }}&nbsp;&nbsp;&nbsp;
              {{
                formInline.orderDetailList[0].startTime.split(" ")[1] +
                " - " +
                formInline.orderDetailList[0].endTime.split(" ")[1]
              }}
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import {} from "@/common/js/public.js";
// import TitleModule from '@/components/titleModule/titleModule'
export default {
  name: "staggeredOrderDetail",
  data() {
    return {
      isEdit: false,
      treeList: [],
      oldName: "",
      resourceList: [],
      formInline: {},
    };
  },
  methods: {
    queryDetail() {
      this.$axios
        .get(
          "/acb/2.0/staggeredOrder/detail?staggeredOrderId=" +
            this.$route.query.staggeredOrderId
        )
        .then((res) => {
          this.formInline = res.value;
        });
    },
  },
  // components: {
  //   TitleModule
  // },
  created() {},
  mounted() {
    this.queryDetail();
  },
  computed: {},
  filters: {
    // stateFilter(state) {
    //   switch (state) {
    //     case 0:
    //       return "未同步";
    //     case 1:
    //       return "同步中";
    //     case 2:
    //       return "同步成功";
    //     case 3:
    //       return "同步失败";
    //     default:
    //       return "未知";
    //   }
    // },
    colorFilter(state) {
      switch (state) {
        case 0:
        case 3:
        case 1:
          return "color_1";
        case 2:
          return "color_2";
        default:
          return "color_1";
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;
  border: 1px solid #C0CCDA;
  border-radius: 4px;
  padding: 20px;
}

.messTitle {
  display: block;
  height: 40px;
  background: rgba(178, 178, 178, 0.6);
  text-align: left;
  width: 100%;
  line-height: 40px;
  text-indent: 40px;
}

.color_1 {
  color: #3d94dd;
}

.color_2 {
  color: #D9001B;
}
</style>
